var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CChartBar',{staticClass:"panel-layout-chart",attrs:{"datasets":[
      {
        data: _vm.yData,
        backgroundColor: '#E55353',
        label: _vm.label,
      },
    ],"labels":_vm.xData,"options":_vm.options}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }